<template>
  <div class="divide-y">
    <div class="py-3 px-4" v-if="!hasItems">沒有商品</div>

    <CartListItemCard
      v-for="item in items"
      :key="item.id"
      :item="item"
      :view="view"
      :success="isSuccess"
    />

    <div class="flex flex-col px-4" v-if="!bill">
      <p class="flex-auto text-sm mt-2" v-if="offer">
        <span>現金優惠 -{{ offer | number_format }}</span>
      </p>
      <p class="flex-auto text-sm mt-2">
        <span>共 {{ count }} 個商品</span>
      </p>
    </div>

    <div class="border-b py-3 leading-none" v-else>
      <div class="text-right text-sm text-chicago mb-2">
        小計 {{ currency }}{{ amount | number_format }}
      </div>
      <!-- <span v-if="takeaway">運費 {{currency}}{{fee}}<br></span> -->
      <div class="font-bold text-sm text-primary">
        合計 {{ currency }}<span class="text-lg">{{ total | number_format }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CartListItemCard from "@/components/cart/CartListItemCard";

export default {
  components: { CartListItemCard },
  props: {
    view: Boolean,
    amount: [Number, String],
    offer: [Number, String],
    count: [Number, String],
    items: Array,
    hasItems: Boolean,
    takeaway: Boolean,
    fee: [Number, String],
    bill: Boolean,
    total: [Number, String],
    status: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isSuccess() {
      return this.status.name == "success";
    },
  },
};
</script>
