<template>
  <div>
    <CartPanel @toggle="toggle"/>
    <Dialog v-if="show" @close="() => (show = false)" :outsideClickable="true">
      <CartList />
    </Dialog>
  </div>
</template>

<script>
import CartList from "@/components/cart/CartList";

import CartPanel from "@/components/cart/CartPanel.vue";
import Dialog from "@luminexs/components/modal/Dialog.vue";

export default {
  components: { CartList, CartPanel, Dialog },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      if (this.atCheckout || this.atConfirm) return false;
      this.show = !this.show;
    },
  },

  
};
</script>
