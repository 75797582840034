import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _665c02ce = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _50250c88 = () => interopDefault(import('../pages/bindAccount.vue' /* webpackChunkName: "pages/bindAccount" */))
const _61469fa6 = () => interopDefault(import('../pages/consent.vue' /* webpackChunkName: "pages/consent" */))
const _0b7a01bc = () => interopDefault(import('../pages/me.vue' /* webpackChunkName: "pages/me" */))
const _6f98d4dd = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _473bb0df = () => interopDefault(import('../pages/procedure.vue' /* webpackChunkName: "pages/procedure" */))
const _4dde3a48 = () => interopDefault(import('../pages/setting.vue' /* webpackChunkName: "pages/setting" */))
const _1cd25e84 = () => interopDefault(import('../pages/takings/index.vue' /* webpackChunkName: "pages/takings/index" */))
const _1d963685 = () => interopDefault(import('../pages/wx.vue' /* webpackChunkName: "pages/wx" */))
const _dfc8397c = () => interopDefault(import('../pages/auth/binding.vue' /* webpackChunkName: "pages/auth/binding" */))
const _770e56f6 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _139976d4 = () => interopDefault(import('../pages/auth/help.vue' /* webpackChunkName: "pages/auth/help" */))
const _a3321134 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _1ea11fb6 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _450b727d = () => interopDefault(import('../pages/oauth/authorize.vue' /* webpackChunkName: "pages/oauth/authorize" */))
const _1edd7cea = () => interopDefault(import('../pages/order/checkout.vue' /* webpackChunkName: "pages/order/checkout" */))
const _1fc1c9ea = () => interopDefault(import('../pages/order/confirm.vue' /* webpackChunkName: "pages/order/confirm" */))
const _146ee3b4 = () => interopDefault(import('../pages/teams/policies/privacy.vue' /* webpackChunkName: "pages/teams/policies/privacy" */))
const _54a9c14a = () => interopDefault(import('../pages/teams/policies/refund.vue' /* webpackChunkName: "pages/teams/policies/refund" */))
const _293bd2d3 = () => interopDefault(import('../pages/teams/policies/service.vue' /* webpackChunkName: "pages/teams/policies/service" */))
const _26ec4d44 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _436c4715 = () => interopDefault(import('../pages/order/product/_product.vue' /* webpackChunkName: "pages/order/product/_product" */))
const _2afd5ca9 = () => interopDefault(import('../pages/takings/_id/index.vue' /* webpackChunkName: "pages/takings/_id/index" */))
const _076e348c = () => interopDefault(import('../pages/takings/_id/upload.vue' /* webpackChunkName: "pages/takings/_id/upload" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _665c02ce,
    name: "about___hk"
  }, {
    path: "/bindAccount",
    component: _50250c88,
    name: "bindAccount___hk"
  }, {
    path: "/consent",
    component: _61469fa6,
    name: "consent___hk"
  }, {
    path: "/me",
    component: _0b7a01bc,
    name: "me___hk"
  }, {
    path: "/order",
    component: _6f98d4dd,
    name: "order___hk"
  }, {
    path: "/procedure",
    component: _473bb0df,
    name: "procedure___hk"
  }, {
    path: "/setting",
    component: _4dde3a48,
    name: "setting___hk"
  }, {
    path: "/takings",
    component: _1cd25e84,
    name: "takings___hk"
  }, {
    path: "/wx",
    component: _1d963685,
    name: "wx___hk"
  }, {
    path: "/auth/binding",
    component: _dfc8397c,
    name: "auth-binding___hk"
  }, {
    path: "/auth/forgot",
    component: _770e56f6,
    name: "auth-forgot___hk"
  }, {
    path: "/auth/help",
    component: _139976d4,
    name: "auth-help___hk"
  }, {
    path: "/auth/login",
    component: _a3321134,
    name: "auth-login___hk"
  }, {
    path: "/auth/register",
    component: _1ea11fb6,
    name: "auth-register___hk"
  }, {
    path: "/oauth/authorize",
    component: _450b727d,
    name: "oauth-authorize___hk"
  }, {
    path: "/order/checkout",
    component: _1edd7cea,
    name: "order-checkout___hk"
  }, {
    path: "/order/confirm",
    component: _1fc1c9ea,
    name: "order-confirm___hk"
  }, {
    path: "/teams/policies/privacy",
    component: _146ee3b4,
    name: "teams-policies-privacy___hk"
  }, {
    path: "/teams/policies/refund",
    component: _54a9c14a,
    name: "teams-policies-refund___hk"
  }, {
    path: "/teams/policies/service",
    component: _293bd2d3,
    name: "teams-policies-service___hk"
  }, {
    path: "/",
    component: _26ec4d44,
    name: "index___hk"
  }, {
    path: "/order/product/:product?",
    component: _436c4715,
    name: "order-product-product___hk"
  }, {
    path: "/takings/:id",
    component: _2afd5ca9,
    name: "takings-id___hk"
  }, {
    path: "/takings/:id/upload",
    component: _076e348c,
    name: "takings-id-upload___hk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
