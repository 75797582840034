<template>
  <div>
    <div class="max-w-screen-sm m-auto bg-white h-app w-full">
      <!-- 內容 -->
      <div class="h-full scrollbar-hide overflow-y-scroll scrolling-touch">
        <nuxt />
      </div>
      <!-- 底部導覽 -->
      <div class="fixed bottom-0 pb-safe w-full inset-x-0">
        <BottomNavigationCard />
      </div>
    </div>

    <!-- 產品詳細 -->
    <Modal class="max-h-app" v-if="enable" @close="cancel" view="sideUp" ref="parent">
      <Specification @close="$refs.parent.close()" />
    </Modal>

    <!-- 銀行卡&交易截圖圖例 -->
    <Modal class="max-h-app" v-if="legend" @close="cancelLegend" view="sideUp">
      <BankLegend />
    </Modal>

    <!-- 購物車 -->
    <ShoppingCart v-if="showCart" />
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Specification from "@/components/product/Specification";
import Modal from "@luminexs/components/modal/Modal.vue";
import ShoppingCart from "@/components/cart/ShoppingCart";
import BankLegend from "@/components/takings/BankLegend.vue";
import BottomNavigationCard from "@/components/card/BottomNavigationCard.vue";

export default {
  components: {
    Specification,
    ShoppingCart,
    Modal,
    BankLegend,
    BottomNavigationCard,
  },
  head() {
    const info = this.$store.getters["app/info"];
    const imgs = this.$store.getters["app/imgs"];
    return {
      title: info.name,
      meta: [
        {
          hid: "description",
          name: "description",
          content: info.description,
        },
        {
          hid: "keywords",
          name: "keywords",
          content: info.keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: info.name,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: info.description,
        },
        {
          hid: "og:image",
          property: "og:image",
          content: imgs.logo,
        },
        {
          hid: "og:url",
          property: "og:url",
          content: info.web,
        }
      ],
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: imgs.logo,
        },
      ],
    };
  },
  data() {
    return {
      popup: false,
      show_consent: false,
    };
  },
  methods: {
    ...mapActions({
      cancel: "specification/cancel",
      cancelLegend: "taking/cancelLegend",
    }),
  },
  computed: {
    ...mapGetters({
      enable: "specification/enable",
      legend: "taking/legend",
    }),
    showCart() {
      return ["index", "order"].includes(
        this.$route.name?.replace(`___${this.$i18n.locale}`, "") // replace ___ to empty string
      );
    },
  },
};
</script>
