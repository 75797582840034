<template>
  <div class="relative">
    <transition
      enter-active-class="transition ease-in-out duration-300 transform"
      leave-active-class="transition ease-in-out duration-300 transform"
      enter-class="translate-y-full"
      leave-to-class="translate-y-full"
      enter-to-class="translate-y-0"
      leave-class="translate-y-0"
    >
      <div
        class="flex flex-col h-full max-h-app overflow-hidden max-w-screen-sm w-full mx-auto bg-white rounded-t-md"
        v-if="show"
      >
        <!-- 頂部 -->
        <div
          class="flex-none flex justify-between items-center bg-main text-white p-4"
        >
          <button type="button" @click="close"><XIcon /></button>
          <button
            type="button"
            class="px-4 bg-red-700 text-sm rounded-full py-1"
            v-if="hasItems"
            @click="clear"
          >
            清空購物車
          </button>
        </div>

        <!-- 內容 -->
        <div class="flex-grow overflow-y-scroll bg-white">
          <CartListItem
            :amount="amount"
            :items="items"
            :count="count"
            :hasItems="hasItems"
            :offer="offer"
          />
          <div class="h-20" />
        </div>

        <!-- 底部 -->
        <div
          class="flex-none flex items-center justify-between pb-safe h-14 border-t"
        >
          <div class="font-bold px-5 text-xs">
            {{ currency
            }}<span class="text-base">{{ amount | number_format }}</span>
          </div>
          <button
            type="button"
            class="text-xl text-white px-10 font-bold h-full"
            :class="{
              'bg-main': hasItems,
              'bg-gray-500 cursor-not-allowed': !hasItems,
            }"
            @click="checkout"
          >
            提交
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CartListItem from "@/components/cart/CartListItem";
import {XIcon} from "@vue-hero-icons/outline";
import {mapGetters, mapActions} from "vuex";

export default {
  components: {CartListItem, XIcon},

  methods: {
    ...mapActions({
      clear: "cart/clear",
    }),

    close() {
      this.$parent.close();
    },

    checkout() {
      if (!this.hasItems) return;
      this.close();
      this.$router.push("/order/checkout");
    },
  },

  computed: {
    ...mapGetters({
      amount: "cart/amount",
      offer: "cart/offer",
      count: "cart/count",
      hasItems: "cart/hasItems",
      items: "cart/items",
    }),
    atCheckout() {
      return this.$route.name == "order-checkout";
    },
    atConfirm() {
      return this.$route.name == "order-confirm";
    },
    show() {
      return this.$parent.show;
    },
  },
};
</script>
