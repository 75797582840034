import Vue from "vue";
import { mapGetters } from "vuex";
import numeral from "numeral";

const Validation = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        _goSocial(url) {
          if (!url) return;
          window.open(url, "_self");
        },
        GoBack() {
          this.$router.go(-1);
        },
        setQuery(query) {
          this.$router.push(this.localePath({ query }));
        },
        mapQuery(query) {
          this.setQuery({ ...this.$route.query, ...query });
        },
        call() {
          this.$router.push(this.localePath({ name: "me-about" }));
          // window.open(`https://work.weixin.qq.com/kfid/kfc70aed6043ba5a53f?enc_scene=ENC3WbRuuDcq6c6S1kcuAbqKKfGZDKpVxXAqtf8LcthPgiuUu3KPNyHeCAsERqQ95AURE`)
        },
        isGuest(path = null) {
          if (this.$auth.loggedIn) return false;
          const redirect = path ?? this.$route.fullPath;

          // set redirect to auth redirect
          this.$auth.$storage.setUniversal("redirect", redirect);

          this.$router.push(
            this.localePath({
              name: "auth-login",
              query: { redirect },
            })
          );
          return true;
        },

        handleErrorsMessage({ response }) {
          const { status, data } = response;

          if (status == 422 && data.errors) {
            const key = Object.keys(data.errors)[0];
            const message = data.errors[key];
            if (message.length) {
              this.$loader.error(message[0]);
            }
            // this.$toast.error(data.errors[key][0]);
          }
        },
      },
      computed: {
        ...mapGetters({
          inMpayMiniApp: "app/inMpayMiniApp",
          langs: "app/lang",
        }),
        mytel() {
          return process.env.TEL;
        },
        currency() {
          return this.current_lang?.currecny ?? process.env.CURRECNY;;
        },
        current_lang() {
          return this.langs.find((item) => item.locale == this.$i18n.locale);
        },
        inMo() {
          return this.$i18n.locale == "mo";
        },
        loggedInUser() {
          return this.$auth.loggedIn ? this.$auth.user.data : {};
        },
        myinfo() {
          return this.loggedInUser;
        },
        inWeiXin() {
          if (process.server) return false;

          const ua = navigator.userAgent.toLowerCase();
          if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
          }
          return false;
        },

      },
      filters: {
        number_format(value, fixed) {
          // 判斷是否有小數點
          if (value.toString().includes(".")) {
            return numeral(value).format("0,0");
          }

          return numeral(value).format(
            fixed ? fixed : process.env.CURRENCY_FORMAT
          );
        },
      },
    });
  },
};

Vue.use(Validation);
